import { useEffect, useState, useCallback } from 'react'
import { API_RECIPES_URL } from '../../config'
import { convertCurrency, extractUTM } from '../../helpers'
import Panel from '../UI/Panel'
import classes from './atable.module.css'

const AUTM = () => {
  const [adata, setAdata] = useState([])

  // Fetch orders
  const fetchData = useCallback(async (loader = true) => {
    try {
      const response = await fetch(API_RECIPES_URL + '?act=stats_utm')

      if (!response.ok) {
        throw new Error('Ошибка доступа к API.')
      }

      const data = await response.json()

      setAdata(data.data)
      console.log(adata)
    } catch (error) {
      console.log(error.message)
    }
  }, [])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return (
    <Panel>
      <div className={classes['title-wrapper']}>
        <h1>UTM и промокоды Mi.Рецепты</h1>
      </div>
      <h2>Заказы по промокодам</h2>
      <div className={classes['atable-wrapper']}>
        <table className={classes['data-table']}>
          <thead>
            <tr>
              <th className={classes['th-m']}>Промокод</th>
              <th className={classes['th-s']}>Заказы</th>
              <th>Итого</th>
            </tr>
          </thead>
          <tbody>
            {adata.promocodes?.map((item) => (
              <tr key={item.promocode}>
                <td>
                  <strong>{item.promocode}</strong>
                </td>
                <td>{item.count}</td>
                <td>{convertCurrency(item.total)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <h2>Заказы по UTM</h2>
      <div className={classes['atable-wrapper']}>
        <table className={classes['data-table']}>
          <thead>
            <tr>
              <th className={classes['th-xl']}>UTM</th>
              <th className={classes['th-s']}>Заказы</th>
              <th>Итого</th>
            </tr>
          </thead>
          <tbody>
            {adata.orders?.map((item) => (
              <tr key={item.utm}>
                <td>
                  <strong>{extractUTM(item.utm)}</strong>
                </td>
                <td>{item.count}</td>
                <td>{convertCurrency(item.total)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <h2>Регистрации по UTM</h2>
      <div className={classes['atable-wrapper']}>
        <table className={classes['data-table']}>
          <thead>
            <tr>
              <th className={classes['th-xl']}>UTM</th>
              <th className={classes['th-m']}>Регистрации / Заказы</th>
              <th>Итого</th>
            </tr>
          </thead>
          <tbody>
            {adata.registration?.map((item) => (
              <tr key={item.utm}>
                <td>
                  <strong>{extractUTM(item.utm)}</strong>
                </td>
                <td>
                  {item.count_registrations} / {item.count_orders}
                </td>
                <td>{convertCurrency(item.total)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Panel>
  )
}

export default AUTM

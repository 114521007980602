import { useEffect, useState, useCallback } from 'react'
import { API_BOOK_URL, API_RECIPES_URL } from '../../config'
import Panel from '../UI/Panel'
import classes from './atable.module.css'
import { convertCurrency, getPercentage } from '../../helpers'

const AGeneral = () => {
  const [adata, setAdata] = useState([])

  // Fetch orders
  const fetchData = useCallback(async (loader = true) => {
    try {
      const response = await fetch(API_BOOK_URL + '?act=stats_general')

      if (!response.ok) {
        throw new Error('Ошибка доступа к API.')
      }

      const data = await response.json()

      setAdata(data.data)
    } catch (error) {
      console.log(error.message)
    }
  }, [])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return (
    <Panel>
      <div className={classes['title-wrapper']}>
        <h1>Статистика Mi.Book</h1>
      </div>

      <div className={classes['atable-wrapper']}>
        <table className={classes['data-table']}>
          <thead>
            <tr>
              <th className={classes['th-l']}>Прибыль</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Всего</td>
              <td>{convertCurrency(adata.total_revenue)}</td>
            </tr>
            <tr>
              <td>Максимум в месяц №1</td>
              <td>
                {convertCurrency(adata.max_revenue?.value)} /{' '}
                {adata.max_revenue?.date}
              </td>
            </tr>
            <tr>
              <td>Максимум в месяц №2</td>
              <td>
                {convertCurrency(adata.max_revenue_second?.value)} /{' '}
                {adata.max_revenue_second?.date}
              </td>
            </tr>
            <tr>
              <td>Максимум в день №1</td>
              <td>
                {convertCurrency(adata.max_revenue_day?.value)} /{' '}
                {adata.max_revenue_day?.date}
              </td>
            </tr>
            <tr>
              <td>Максимум в день №2</td>
              <td>
                {convertCurrency(adata.max_revenue_day_second?.value)} /{' '}
                {adata.max_revenue_day_second?.date}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className={classes['atable-wrapper']}>
        <table className={classes['data-table']}>
          <thead>
            <tr>
              <th className={classes['th-l']}>Заказы</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Покупателей</td>
              <td>{adata.total_users}</td>
            </tr>
            <tr>
              <td>Заказов</td>
              <td>{adata.total_orders}</td>
            </tr>
            <tr>
              <td>Максимум в месяц №1</td>
              <td>
                {adata.max_orders?.value} / {adata.max_orders?.date}
              </td>
            </tr>
            <tr>
              <td>Максимум в месяц №2</td>
              <td>
                {adata.max_orders_second?.value} /{' '}
                {adata.max_orders_second?.date}
              </td>
            </tr>
            <tr>
              <td>Максимум в день №1</td>
              <td>
                {adata.max_orders_day?.value} / {adata.max_orders_day?.date}
              </td>
            </tr>
            <tr>
              <td>Максимум в день №2</td>
              <td>
                {adata.max_orders_day_second?.value} /{' '}
                {adata.max_orders_day_second?.date}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className={classes['atable-wrapper']}>
        <table className={classes['data-table']}>
          <thead>
            <tr>
              <th className={classes['th-l']}>Возврат покупателей</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Повторные заказы</td>
              <td>
                {adata.users_return?.count} (
                {getPercentage(adata.users_return?.count, adata.total_users)})
              </td>
            </tr>
            <tr>
              <td>Прибыль</td>
              <td>
                {convertCurrency(adata.users_return?.revenue)} (
                {getPercentage(
                  adata.users_return?.revenue,
                  adata.total_revenue
                )}
                )
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Panel>
  )
}

export default AGeneral

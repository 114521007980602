import { useEffect, useState, useCallback } from 'react'
import { API_RECIPES_URL } from '../../config'
import { convertCurrency, convertDateFormatForDisplay } from '../../helpers'
import Panel from '../UI/Panel'
import classes from './atable.module.css'

const ARepayments = () => {
  const [adata, setAdata] = useState([])
  // Fetch orders
  const fetchData = useCallback(async (loader = true) => {
    try {
      const response = await fetch(API_RECIPES_URL + '?act=stats_repayments')

      if (!response.ok) {
        throw new Error('Ошибка доступа к API.')
      }

      const data = await response.json()
      let sortedData = data.data.daily

      sortedData.sort(function (a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(a.date) - new Date(b.date)
      })

      setAdata({ daily: sortedData, monthly: data.data.monthly })
    } catch (error) {
      console.log(error.message)
    }
  }, [])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  // Totals
  let total = 0

  adata.daily?.forEach((item) => {
    total = total + item.price
  })

  return (
    <Panel>
      <div className={classes['title-wrapper']}>
        <h1>
          Подписки Mi.Рецепты - автопродление ({adata.daily?.length} /{' '}
          {convertCurrency(total)})
        </h1>
      </div>
      <h2>Помесячно</h2>
      <div className={classes['atable-wrapper']}>
        <table className={classes['data-table']}>
          <thead>
            <tr>
              <th className={classes['th-s']}>Месяц</th>
              <th className={classes['th-s']}>Подписки</th>
              <th>Сумма</th>
            </tr>
          </thead>
          <tbody>
            {adata.monthly?.map((item) => (
              <tr key={item.date}>
                <td>
                  <strong>{item.date}</strong>
                </td>
                <td>{item.count}</td>
                <td>{convertCurrency(item.total)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <h2>Все будущие автоплатежи</h2>
      <div className={classes['atable-wrapper']}>
        <table className={classes['data-table']}>
          <thead>
            <tr>
              <th>Дата автосписания</th>
              <th>User ID / Email</th>
              <th>Продукт</th>
              <th>Сумма</th>
              <th>Payment ID</th>
            </tr>
          </thead>
          <tbody>
            {adata.daily?.map((item) => (
              <tr key={item.payment_id}>
                <td>
                  <strong>{convertDateFormatForDisplay(item.date)}</strong>
                </td>
                <td>
                  {item.userid} / {item.email}
                </td>
                <td>{item.months} мес.</td>
                <td>{convertCurrency(item.price)}</td>
                <td>{item.payment_id}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Panel>
  )
}

export default ARepayments

import { convertCurrency } from '../../helpers'
import classes from './Widget.module.css'

const Widget = (props) => {
  let value = convertCurrency(props.value)

  return (
    <div className={classes['widget-box']}>
      <div className={classes['widget-content']}>
        <div className={classes['widget-title']}>
          {props.title}
          <span className={classes['widget-total']}>{props.total} заказов</span>
        </div>
        <div className={classes['widget-value']}>{value}</div>
      </div>
      <div className={classes['widget-icon']}>{props.icon}</div>
    </div>
  )
}

export default Widget

import { useState, useEffect, useCallback } from 'react'
import { convertDateFormat, convertCurrency } from '../../helpers'
import { API_REFRESH, API_BOOK_URL } from '../../config.js'

import classes from './OrdersTable.module.css'

import OrdersTableFilter from './OrdersTableFilter'
import TableLoader from './TableLoader'
import OrdersTableLayout from './OrdersTableLayout'
import Message from '../UI/Message'
import AddNewOrder from './AddNewOrder'
import EditOrder from './EditOrder'
import Panel from '../UI/Panel'

const OrdersTable = (props) => {
  const todayDate = convertDateFormat(new Date())

  const [orders, setOrders] = useState([])
  const [ordersToDisplay, setOrdersToDisplay] = useState([])

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  const [currentDate, setCurrentDate] = useState(todayDate)
  const [currentDateTo, setCurrentDateTo] = useState(todayDate)
  const [query, setQuery] = useState('')
  const [lock, setLock] = useState(true)

  const [addProductDialog, setAddProductDialog] = useState(false)
  const [editProductDialog, setEditProductDialog] = useState(false)
  const [editableOrder, setEditableOrder] = useState({})

  const [message, setMessage] = useState({})

  // Close message
  const messageCloseHandler = () => {
    setMessage({})
  }

  // Action handler
  const actionHandler = (action) => {
    console.log(action) // type, id

    let actionEndPoint = ''

    // Edit order
    if (action.type === 'edit') {
      const objIndex = orders.findIndex((orders) => orders.id === action.id)
      const order = orders[objIndex]

      setEditableOrder(order)
      setEditProductDialog(true)
    }

    // Other actions
    if (
      action.type === 'delete' &&
      window.confirm('Удалить запись #' + action.id + '?')
    ) {
      actionEndPoint = API_BOOK_URL + '?act=delete&id=' + action.id
    }

    if (action.type === 'resend') {
      actionEndPoint = API_BOOK_URL + '?act=resend&id=' + action.id
    }

    if (action.type === 'markaspaid') {
      actionEndPoint = API_BOOK_URL + '?act=markaspaid&id=' + action.id
    }

    if (action.type === 'getlinks') {
      actionEndPoint = API_BOOK_URL + '?act=getlinks&id=' + action.id
    }

    if (action.type === 'addremarketing') {
      actionEndPoint = API_BOOK_URL + '?act=addremarketing&id=' + action.id
    }

    if (action.type === 'recheck') {
      actionEndPoint = API_BOOK_URL + '?act=recheck&id=' + action.id
    }

    if (actionEndPoint !== '') {
      processActionRequest(actionEndPoint, action)
    }
  }

  // Process actions
  const processActionRequest = useCallback(
    async (actionEndPoint, action) => {
      try {
        const response = await fetch(actionEndPoint)

        if (!response.ok) {
          throw new Error('Ошибка доступа к API.')
        }

        const data = await response.json()
        setMessage({ message: data.message, type: data.type })

        // Remade array for mark as paid
        if (action.type === 'markaspaid' && data.type !== 'error') {
          const updatedOrders = orders.map((obj) => {
            if (obj.id === action.id) {
              return { ...obj, status: 'paid' }
            }

            return obj
          })

          setOrders(updatedOrders)
        }

        // Remade array for mark as recheck
        if (
          action.type === 'recheck' &&
          data.type !== 'error' &&
          data.recheck === 'paid'
        ) {
          const updatedOrders = orders.map((obj) => {
            if (obj.id === action.id) {
              return { ...obj, status: 'paid' }
            }

            return obj
          })

          setOrders(updatedOrders)
        }

        // Remade array for delete
        if (action.type === 'delete') {
          let updatedOrders = orders.filter((order) => {
            return order.id !== action.id
          })
          setOrders(updatedOrders)
        }
      } catch (error) {
        setMessage({ message: error.message, type: 'error' })
      }
    },
    [orders]
  )

  // Filter order by date
  const filterOrdersByDate = useCallback(
    (date, dateTo) => {
      setCurrentDate(date)
      setCurrentDateTo(dateTo)

      const filteredOrders = orders.filter((order) => {
        const dateFromFilter = convertDateFormat(new Date(date))
        const dateToFilter = convertDateFormat(new Date(dateTo))
        const dateFromOrder = convertDateFormat(new Date(order.date))

        if (dateTo !== date) {
          return (
            Date.parse(dateFromOrder) >= Date.parse(dateFromFilter) &&
            Date.parse(dateFromOrder) <= Date.parse(dateToFilter)
          )
        }

        return dateFromFilter === dateFromOrder
      })

      setOrdersToDisplay(filteredOrders)

      setQuery('') // Clear email field
    },
    [orders]
  )

  // Filter orders by email
  const filterOrdersByEmail = (email) => {
    if (email === '') {
      filterOrdersByDate(currentDate, currentDateTo)
    } else {
      const filteredOrders = orders.filter((order) => {
        return order.email.includes(email)
      })

      console.log('filtering email:', email)

      setOrdersToDisplay(filteredOrders)
    }
  }

  // Change dates from filter
  const dateChangedHandler = (date) => {
    if (lock) {
      filterOrdersByDate(date, date)
    } else {
      filterOrdersByDate(date, currentDateTo)
    }
  }

  const dateToChangedHandler = (dateTo) => {
    if (lock) {
      filterOrdersByDate(dateTo, dateTo)
    } else {
      filterOrdersByDate(currentDate, dateTo)
    }
  }

  // Reset date to today
  const onFilterResetHandler = () => {
    filterOrdersByDate(todayDate, todayDate)
  }

  // Filter from search
  const onQueryChangeHandler = (email) => {
    setQuery(email.trim())

    filterOrdersByEmail(email)
  }

  // Navigate prev/next
  const navLeftHandler = () => {
    const d = new Date(currentDate)
    d.setDate(d.getDate() - 1)

    const dateToFilter = convertDateFormat(d)

    filterOrdersByDate(dateToFilter, dateToFilter)
  }

  const navRightHandler = () => {
    const d = new Date(currentDate)
    d.setDate(d.getDate() + 1)

    const dateToFilter = convertDateFormat(d)
    filterOrdersByDate(dateToFilter, dateToFilter)
  }

  // Fetch orders
  const fetchOrdersHandler = useCallback(async (loader = true) => {
    if (loader) {
      setIsLoading(true)
    }

    setError(null)

    try {
      const response = await fetch(API_BOOK_URL + '?act=getorders')

      if (!response.ok) {
        throw new Error('Ошибка доступа к API.')
      }

      const data = await response.json()

      setOrders(data)
    } catch (error) {
      setError(error.message)
    }

    if (loader) {
      setIsLoading(false)
    }
  }, [])

  // Submit new order
  const newOrderSubmitHandler = async (order) => {
    setAddProductDialog(false)

    const response = await fetch(API_BOOK_URL + '?act=addorder', {
      method: 'POST',
      body: JSON.stringify(order),
      headers: {
        'Content-Type': 'application/json',
      },
    })

    const data = await response.json()
    setMessage({ message: data.message, type: data.type })

    setOrders([
      {
        id: data.id, // set from server
        transaction_id: 'manual',
        date: order.date,
        type: order.type,
        product_type: order.product_type,
        status: order.status,
        total: parseInt(order.total),
        email: order.email,
        utm: '',
      },
      ...orders,
    ])
  }

  // Save edited order
  const orderSavedHandler = async (order) => {
    setEditableOrder({})
    setEditProductDialog(false)

    const updatedOrders = orders.map((obj) => {
      if (obj.id === order.id) {
        return order
      }

      return obj
    })

    setOrders(updatedOrders)

    const response = await fetch(API_BOOK_URL + '?act=update', {
      method: 'POST',
      body: JSON.stringify(order),
      headers: {
        'Content-Type': 'application/json',
      },
    })

    setMessage({ message: response.message, type: response.type })
  }

  // Refresh table data from web
  const onRefreshHandler = () => {
    fetchOrdersHandler()
  }

  // On lock filter
  const onLockHandler = () => {
    setLock((prevLock) => {
      return !prevLock
    })
  }

  // Close add product dialog
  const closeAddOrderHandler = () => {
    setAddProductDialog(false)
  }

  // Open add product dialog
  const openAddOrderHandler = () => {
    setAddProductDialog(true)
  }

  // Close add product dialog
  const closeEditOrderHandler = () => {
    setEditProductDialog(false)
  }

  // Export Emails
  const exportEmailsHandler = (e) => {
    e.preventDefault()
    document.location.href = API_BOOK_URL + '?act=export-paid'

    var interval = setInterval(() => {
      document.location.href = API_BOOK_URL + '?act=export-free'
      clearInterval(interval)
    }, 500)
  }

  // Use Effects
  useEffect(() => {
    fetchOrdersHandler()
  }, [fetchOrdersHandler])

  useEffect(() => {
    filterOrdersByDate(currentDate, currentDateTo)
  }, [filterOrdersByDate, currentDate, currentDateTo])

  // Live view
  useEffect(() => {
    const interval = setInterval(() => {
      fetchOrdersHandler(false)
    }, API_REFRESH)

    return () => clearInterval(interval)
  })

  // Content
  let content = <p>Заказов нет.</p>

  if (isLoading) {
    content = <TableLoader />
  }

  if (error) {
    content = <Message content={error} type="error" dismissable={false} />
  }

  if (!isLoading & (ordersToDisplay.length > 0)) {
    let totalTmp = 0
    let count = 0

    ordersToDisplay.forEach((order) => {
      if (order.status === 'paid') {
        totalTmp = totalTmp + order.total
        count++
      }
    })

    const total = convertCurrency(totalTmp)

    content = (
      <OrdersTableLayout
        orders={ordersToDisplay}
        count={count}
        total={total}
        onAction={actionHandler}
      />
    )
  }

  return (
    <Panel>
      {addProductDialog && (
        <AddNewOrder
          onClose={closeAddOrderHandler}
          onNewOrderSubmit={newOrderSubmitHandler}
        />
      )}
      {editProductDialog && (
        <EditOrder
          order={editableOrder}
          onClose={closeEditOrderHandler}
          onOrderSaved={orderSavedHandler}
        />
      )}
      <div className={classes['title-wrapper']}>
        <h1>
          Заказы Mi.Book
          <span
            className={`${classes['online-badge']} ${
              error && classes['online-badge__error']
            }`}
          ></span>
        </h1>
        <button className="secondary" onClick={exportEmailsHandler}>
          Экспорт
        </button>
        <button
          className={classes['btn-add-order']}
          onClick={openAddOrderHandler}
        >
          Добавить заказ
        </button>
      </div>

      {message.message && (
        <Message
          content={message.message}
          type={message.type}
          onClose={messageCloseHandler}
        />
      )}
      <OrdersTableFilter
        date={new Date(currentDate).toLocaleDateString('en-CA')}
        dateTo={new Date(currentDateTo).toLocaleDateString('en-CA')}
        onDateChange={dateChangedHandler}
        onDateToChange={dateToChangedHandler}
        query={query}
        onQueryChange={onQueryChangeHandler}
        onReset={onFilterResetHandler}
        onRefresh={onRefreshHandler}
        onLock={onLockHandler}
        locked={lock}
      />
      {content}
      <div className={classes['table-nav']}>
        <button onClick={navLeftHandler}>Назад</button>
        {todayDate !== currentDate && (
          <button onClick={navRightHandler}>Вперед</button>
        )}
      </div>
    </Panel>
  )
}

export default OrdersTable

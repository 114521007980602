import { useEffect, useState, useCallback } from 'react'
import { API_RECIPES_URL, aRecipesList } from '../../config'
import { convertCurrency, sumValues, getPercentage } from '../../helpers'
import Panel from '../UI/Panel'
import classes from './atable.module.css'
import AMonthlyRecipesGraph from './AMonthlyRecipesGraph'

const AMonthlyRecipes = () => {
  const [adata, setAdata] = useState([])

  // Fetch orders
  const fetchData = useCallback(async (loader = true) => {
    try {
      const response = await fetch(API_RECIPES_URL + '?act=stats_monthly')

      if (!response.ok) {
        throw new Error('Ошибка доступа к API.')
      }

      const data = await response.json()

      let objects = []

      Object.entries(data.data).forEach((item) => {
        let objEntry = {
          label: item[0],
          total: 0,
          count: 0,
        }

        aRecipesList.forEach((el) => {
          objEntry[el] = {
            total: item[1][el] ? item[1][el].total : 0,
            count: item[1][el] ? item[1][el].count : 0,
          }

          if (el !== 'auto') {
            objEntry.total = objEntry.total + objEntry[el].total
            if (item[1][el]) {
              objEntry.count = objEntry.count + objEntry[el].count
            }
          }
        })

        objects.push(objEntry)
      })

      setAdata(objects)
    } catch (error) {
      console.log(error.message)
    }
  }, [])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  // Totals
  let totals = {}
  let counts = {}

  aRecipesList.forEach((product) => {
    totals[product + '_total'] = 0
    counts[product + '_count'] = 0

    adata.forEach((item) => {
      totals[product + '_total'] =
        totals[product + '_total'] + item[product].total
      counts[product + '_count'] =
        counts[product + '_count'] + item[product].count
    })
  })

  const sumTotal = sumValues(totals)
  const sumCounts = sumValues(counts)

  return (
    <Panel>
      <div className={classes['title-wrapper']}>
        <h1>Заказы Mi.Рецепты - помесячно</h1>
      </div>

      <div className={classes['graph-wrapper']}>
        <AMonthlyRecipesGraph data={adata} />
      </div>

      <div className={classes['atable-wrapper']}>
        <table className={classes['data-table']}>
          <thead>
            <tr>
              <th>Месяц</th>
              {aRecipesList.map((product) => (
                <th key={product}>
                  {product === 0 ? (
                    'Вечный доступ'
                  ) : product === 'auto' ? (
                    <span>Из них авто</span>
                  ) : (
                    product + ' мес.'
                  )}
                </th>
              ))}
              <th>Итого</th>
            </tr>
          </thead>
          <tbody>
            {adata.map((item) => (
              <tr key={item.label}>
                <td>
                  <strong>{item.label}</strong>
                </td>
                {aRecipesList.map((product) => (
                  <td
                    key={product}
                    className={
                      product === 'auto' && item[product].total
                        ? 'color-green'
                        : ''
                    }
                  >
                    {convertCurrency(item[product].total)} /{' '}
                    {item[product].count}
                  </td>
                ))}
                <td>
                  {convertCurrency(item.total)} / {item.count}
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <th>Итого</th>
              {aRecipesList.map((product) => (
                <th key={product}>
                  {convertCurrency(totals[product + '_total'])} (
                  {getPercentage(totals[product + '_total'], sumTotal)}){' / '}
                  {counts[product + '_count']}
                </th>
              ))}
              <th>
                {convertCurrency(sumTotal)} / {sumCounts}
              </th>
            </tr>
          </tfoot>
        </table>
      </div>
    </Panel>
  )
}

export default AMonthlyRecipes

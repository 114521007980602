import classes from './OrdersTableLayoutRecipes.module.css'
import OrdersTableRowRecipes from './OrdersTableRowRecipes'

const OrdersTableLayoutRecipes = (props) => {
  return (
    <div className={classes['data-table-wrapper']}>
      <table className={classes['data-table']}>
        <thead>
          <tr>
            <th className={classes['th-1']}></th>
            <th className={classes['th-2']}>ID</th>
            <th className={classes['th-3']}>Тариф</th>
            <th className={classes['th-4']}>Дата</th>
            <th className={classes['th-5']}>Платеж</th>
            <th className={classes['th-6']}>Сумма</th>
            <th className={classes['th-7']}>Email</th>
            <th className={classes['th-8']}>Промокод</th>
            <th className={classes['th-8']}>UTM</th>
          </tr>
        </thead>
        <tbody>
          {props.orders.map((order) => (
            <OrdersTableRowRecipes
              key={order.id}
              id={order.id}
              months={order.months}
              status={order.status} // paid / processing / unpaid / failed
              date={order.date}
              total={order.total}
              email={order.email}
              discount={order.discount}
              promocode={order.promocode}
              onAction={props.onAction}
              paymentType={order.payment_type}
              utm={order.utm}
              auto={order.auto}
            />
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="3">
              <strong>Итого:</strong> {props.count} заказа(ов)
            </td>
            <td colSpan="2"></td>
            <td>{props.total}</td>
            <td colSpan="4"></td>
          </tr>
        </tfoot>
      </table>
    </div>
  )
}

export default OrdersTableLayoutRecipes

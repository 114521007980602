import React from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { aRecipesList } from '../../config'
import { Colors } from 'chart.js'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Colors,
  Title,
  Tooltip,
  Legend
)
export const options = {
  plugins: {
    colors: {
      enabled: true,
    },
    title: {
      display: false,
      text: '',
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
}

const AMonthlyRecipesGraph = (props) => {
  let datasets = []
  let labels = props.data.map((item) => {
    return item.label
  })

  aRecipesList.forEach((product) => {
    if (product === 'auto') {
      const dataset2 = {
        label: 'Автосписания',
        data: [],
        stack: 'Stack 1',
      }

      props.data.forEach((item) => {
        dataset2.data.push(item[product].total)
      })

      datasets.push(dataset2)
    } else {
      const dataset = {
        label: product === 0 ? 'Вечный доступ' : product + ' мес.',
        data: [],
        stack: 'Stack 0',
      }

      props.data.forEach((item) => {
        dataset.data.push(item[product].total)
      })

      datasets.push(dataset)
    }
  })

  return <Bar options={options} data={{ labels, datasets: datasets }} />
}

export default AMonthlyRecipesGraph

import { useRef } from 'react'
import classes from './AddNewOrder.module.css'

import ModalDialog from '../UI/ModalDialog'

const AddNewOrder = (props) => {
  const productField = useRef('')
  const dateField = useRef('')
  const typeField = useRef('')
  const statusField = useRef('')
  const totalField = useRef('')
  const emailField = useRef('')

  const tzoffset = new Date().getTimezoneOffset() * 60000
  let todayDate = new Date(Date.now() - tzoffset)
    .toISOString()
    .replace(/T/, ' ')
    .replace(/\..*/, '')

  const closeHandler = () => {
    props.onClose()
  }

  const formSubmitHandler = (e) => {
    e.preventDefault()
    const orderData = {
      transaction_id: 'manual',
      date: dateField.current.value,
      type: typeField.current.value,
      product_type: productField.current.value,
      status: statusField.current.value,
      total: totalField.current.value,
      email: emailField.current.value,
      promocode: '',
    }
    props.onNewOrderSubmit(orderData)
  }

  return (
    <ModalDialog onClose={closeHandler} title="Добавить заказ">
      <form onSubmit={formSubmitHandler}>
        <div className={classes['control']}>
          <label>Продукт</label>
          <select ref={productField}>
            <option value="mainbook">Книга рецептов</option>
            <option value="bsbook">Безопасность для пап и мам</option>
            <option value="bzgbook">Книга безглютеновых рецептов</option>
            <option value="bundle">Bundle 1 (CHR+M)</option>
            <option value="bundle_2">Bundle 2 (BS+M)</option>
            <option value="bundle_3">Bundle 3 (BS+BZG+M)</option>
            <option value="chrbook">Новогодние рецепты</option>
          </select>
        </div>
        <div className={classes['control']}>
          <label>Дата</label>
          <input
            type="text"
            ref={dateField}
            defaultValue={todayDate}
            placeholder="Дата"
            required
          />
        </div>
        <div className={classes['control']}>
          <label>Платеж</label>
          <select ref={typeField}>
            <option value="paypal">Payoneer</option>
            <option value="manual">Manual</option>
            <option value="online">Online</option>
          </select>
        </div>
        <div className={classes['control']}>
          <label>Статус</label>
          <select ref={statusField}>
            <option value="processing">Processing</option>
            <option value="paid">Paid</option>
            <option value="unpaid">Unpaid</option>
            <option value="failed">Failed</option>
          </select>
        </div>
        <div className={classes['control']}>
          <label>Сумма</label>
          <input type="text" ref={totalField} placeholder="1290" required />
        </div>
        <div className={classes['control']}>
          <label>Email</label>
          <input
            type="text"
            ref={emailField}
            placeholder="email@email.com"
            required
          />
        </div>
        <div className={classes['control']}>
          <button type="submit">Добавить</button>
        </div>
      </form>
    </ModalDialog>
  )
}

export default AddNewOrder

import { useRef } from 'react'
import classes from './AddNewOrderRecipes.module.css'

import ModalDialog from '../UI/ModalDialog'

const AddNewOrderRecipes = (props) => {
  const productField = useRef('')
  const emailField = useRef('')
  const discountField = useRef('')
  const paymentMethodField = useRef('')
  const promocodeField = useRef('')

  const tzoffset = new Date().getTimezoneOffset() * 60000
  let todayDate = new Date(Date.now() - tzoffset)
    .toISOString()
    .replace(/T/, ' ')
    .replace(/\..*/, '')

  const closeHandler = () => {
    props.onClose()
  }

  const formSubmitHandler = (e) => {
    e.preventDefault()
    const orderData = {
      months: productField.current.value,
      email: emailField.current.value,
      discount: discountField.current.value,
      payment_method: paymentMethodField.current.value,
      promocode: promocodeField.current.value,
    }
    props.onNewOrderSubmit(orderData)
  }

  return (
    <ModalDialog onClose={closeHandler} title="Добавить заказ">
      <form onSubmit={formSubmitHandler}>
        <div className={classes['control']}>
          <label>Email</label>
          <input
            type="text"
            ref={emailField}
            placeholder="email@email.com"
            required
          />
        </div>
        <div className={classes['control']}>
          <label>Тариф</label>
          <select ref={productField}>
            <option value="0">Вечный доступ</option>
            <option value="3">3 месяца</option>
            <option value="6">6 месяцев</option>
            <option value="12">1 год</option>
          </select>
        </div>
        <div className={classes['control']}>
          <label>Способ оплаты</label>
          <select ref={paymentMethodField}>
            <option value="boosty">Boosty</option>
            <option value="manual">Вручную</option>
          </select>
        </div>
        <div className={classes['control']}>
          <label>Скидка</label>
          <select ref={discountField}>
            <option value="1">Да</option>
            <option value="0">Нет</option>
          </select>
        </div>
        <div className={classes['control']}>
          <label>Промокод</label>
          <input type="text" ref={promocodeField} placeholder="" />
        </div>
        <div className={classes['control']}>
          <button type="submit">Добавить</button>
        </div>
      </form>
    </ModalDialog>
  )
}

export default AddNewOrderRecipes

import { useRef } from 'react'
import classes from './EditOrder.module.css'

import ModalDialog from '../UI/ModalDialog'

const EditOrder = (props) => {
  const productField = useRef('')
  const dateField = useRef('')
  const typeField = useRef('')
  const statusField = useRef('')
  const totalField = useRef('')
  const emailField = useRef('')

  const closeHandler = () => {
    props.onClose()
  }

  const formSubmitHandler = (e) => {
    e.preventDefault()
    const orderData = {
      id: props.order.id,
      transaction_id: 'manual',
      date: dateField.current.value,
      type: typeField.current.value,
      product_type: productField.current.value,
      status: statusField.current.value,
      total: parseInt(totalField.current.value),
      email: emailField.current.value,
      promocode: '',
      utm: '',
    }
    props.onOrderSaved(orderData)
  }

  return (
    <ModalDialog onClose={closeHandler} title="Редактировать заказ">
      <form onSubmit={formSubmitHandler}>
        <div className={classes['control']}>
          <label>Продукт</label>
          <select ref={productField} defaultValue={props.order.product_type}>
            <option value="mainbook">Книга рецептов</option>
            <option value="bsbook">Безопасность для пап и мам</option>
            <option value="bzgbook">Книга безглютеновых рецептов</option>
            <option value="bundle">Bundle 1 (CHR+M)</option>
            <option value="bundle_2">Bundle 2 (BS+M)</option>
            <option value="bundle_3">Bundle 3 (BS+BZG+M)</option>
            <option value="chrbook">Новогодние рецепты</option>
          </select>
        </div>
        <div className={classes['control']}>
          <label>Дата</label>
          <input
            type="text"
            ref={dateField}
            defaultValue={props.order.date}
            placeholder="Дата"
            required
          />
        </div>
        <div className={classes['control']}>
          <label>Платеж</label>
          <select ref={typeField} defaultValue={props.order.type}>
            <option value="paypal">Payoneer</option>
            <option value="manual">Manual</option>
            <option value="online">Online</option>
          </select>
        </div>
        <div className={classes['control']}>
          <label>Статус</label>
          <select ref={statusField} defaultValue={props.order.status}>
            <option value="processing">Processing</option>
            <option value="paid">Paid</option>
            <option value="unpaid">Unpaid</option>
            <option value="failed">Failed</option>
          </select>
        </div>
        <div className={classes['control']}>
          <label>Сумма</label>
          <input
            type="text"
            ref={totalField}
            placeholder="1290"
            required
            defaultValue={props.order.total}
          />
        </div>
        <div className={classes['control']}>
          <label>Email</label>
          <input
            type="text"
            ref={emailField}
            placeholder="email@email.com"
            required
            defaultValue={props.order.email}
          />
        </div>
        <div className={classes['control']}>
          <button type="submit">Сохранить</button>
        </div>
      </form>
    </ModalDialog>
  )
}

export default EditOrder

export const API_BOOK_URL = 'https://book.miukagirl.ru/api/'
export const API_RECIPES_URL = 'https://dash.mirecipes.ru/api/'
export const API_REFRESH = 30000 // 1000 = 1 sec

export const aProductsList = [
  'bundle_3',
  'bundle_2',
  'bundle',
  'mainbook',
  'bsbook',
  'bzgbook',
  'chrbook',
] // Used in analytics

export const aRecipesList = [0, 3, 6, 12, 'auto'] // Used in recipes analytics

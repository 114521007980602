import classes from './OrdersTableLayout.module.css'
import OrdersTableRow from './OrdersTableRow'

const OrdersTableLayout = (props) => {
  return (
    <div className={classes['data-table-wrapper']}>
      <table className={classes['data-table']}>
        <thead>
          <tr>
            <th className={classes['th-1']}></th>
            <th className={classes['th-2']}>ID</th>
            <th className={classes['th-3']}>Продукт</th>
            <th className={classes['th-4']}>Дата</th>
            <th className={classes['th-5']}>Платеж</th>
            <th className={classes['th-6']}>Сумма</th>
            <th className={classes['th-7']}>Email</th>
            <th className={classes['th-8']}>Промокод</th>
            <th className={classes['th-9']}>Leadsource</th>
            <th className={classes['th-10']}>UTM</th>
          </tr>
        </thead>
        <tbody>
          {props.orders.map((order) => (
            <OrdersTableRow
              key={order.id}
              id={order.id}
              product={order.product_type}
              transactionId={order.transaction_id}
              paymentMethod={order.type} // manual, online, paypal
              status={order.status} // paid / processing / unpaid / failed
              date={order.date}
              total={order.total}
              email={order.email}
              promocode={order.promocode}
              leadsource={order.leadsource}
              utm={order.utm}
              onAction={props.onAction}
            />
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="3">
              <strong>Итого:</strong> {props.count} заказа(ов)
            </td>
            <td colSpan="2"></td>
            <td>{props.total}</td>
            <td colSpan="4"></td>
          </tr>
        </tfoot>
      </table>
    </div>
  )
}

export default OrdersTableLayout

import { createSlice } from '@reduxjs/toolkit'

const authSlice = createSlice({
  name: 'auth',
  initialState: { isLogged: false, user: null },
  reducers: {
    login(state) {
      state.isLogged = true
    },
    logout(state) {
      state.isLogged = false
    },
    setUser(state, action) {
      state.user = action.payload
    },
  },
})

export const authActions = authSlice.actions

export default authSlice

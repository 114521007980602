import { NavLink } from 'react-router-dom'
import classes from './TopMenuItem.module.css'

const TopMenuItem = (props) => {
  return (
    <div className={classes['action-link']}>
      <NavLink
        className={(navData) => {
          return navData.isActive ? classes.active : ''
        }}
        to={props.page}
        onClick={props.onClick}
      >
        {props.title}
      </NavLink>
    </div>
  )
}

export default TopMenuItem

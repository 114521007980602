// Get page title
export const getPageTitle = (page) => {
  if (page.includes('/orders')) {
    return 'Заказы'
  }

  if (page.includes('/analytics')) {
    return 'Аналитика'
  }

  return 'Страница не найдена'
}

// Convert date to DD-MM-YYYY
export const convertDateFormat = (d) => {
  return d.toLocaleDateString('en-GB', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  })
}

// Convert date to DD-MM-YYYY H:i:s
export const convertDateFormatFull = (d) => {
  return (
    d.toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    }) +
    ' ' +
    d.toLocaleTimeString('en-GB')
  )
}

// Convert date to YYYY-MM-DD
export const convertDateFormatForInput = (date) => {
  const d = Date.parse(date)
  const date_obj = new Date(d)
  return `${date_obj.getFullYear()}-${date_obj.toLocaleString('default', {
    month: '2-digit',
  })}-${date_obj.toLocaleString('default', { day: '2-digit' })}`
}

// Convert date for display - 7 Dec 2023, 02:37:02
export const convertDateFormatForDisplay = (date) => {
  return new Date(date).toLocaleDateString('en-GB', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  })
}

// Convert date for display - Dec 2023 - not working in FF
export const convertDateFormatForShortDisplay = (date) => {
  return date
}
// Convert date for display - 1 Dec 2023
export const convertDateFormatForShortDayDisplay = (date) => {
  return new Date(date).toLocaleDateString('default', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  })
}

// Convert currency display format
export const convertCurrency = (value) => {
  return Intl.NumberFormat('ru-RU', {
    style: 'currency',
    currency: 'RUB',
  }).format(value)
}

// Sum values in object
export const sumValues = (obj) => Object.values(obj).reduce((a, b) => a + b, 0)

// Get percentage
export const getPercentage = (item, total) => {
  if (total === 0) return 0 + '%'
  console.log('round: ', item, total)

  return Math.round((item / total) * 100) + '%'
}

// Extract UTM
export const extractUTM = (utm) => {
  return utm.replace(/\|\|/g, ' > ')
}

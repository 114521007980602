import { useEffect, useState, useCallback } from 'react'
import { API_RECIPES_URL } from '../../config'
import Panel from '../UI/Panel'
import classes from './atable.module.css'

const ARegistrations = () => {
  const [adata, setAdata] = useState([])

  // Fetch orders
  const fetchData = useCallback(async (loader = true) => {
    try {
      const response = await fetch(API_RECIPES_URL + '?act=stats_registration')

      if (!response.ok) {
        throw new Error('Ошибка доступа к API.')
      }

      const data = await response.json()

      setAdata(data.data)
      console.log(adata)
    } catch (error) {
      console.log(error.message)
    }
  }, [])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  // Totals
  let total = 0

  adata.monthly?.forEach((item) => {
    total = total + item.count
  })

  return (
    <Panel>
      <div className={classes['title-wrapper']}>
        <h1>Регистрации пользователей Mi.Рецепты ({total})</h1>
      </div>
      <h2>Помесячно</h2>
      <div className={classes['atable-wrapper']}>
        <table className={classes['data-table']}>
          <thead>
            <tr>
              <th className={classes['th-s']}>Месяц</th>
              <th>Регистрации</th>
            </tr>
          </thead>
          <tbody>
            {adata.monthly?.map((item) => (
              <tr key={item.date}>
                <td>
                  <strong>{item.date}</strong>
                </td>
                <td>{item.count}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <h2>По дням</h2>
      <div className={classes['atable-wrapper']}>
        <table className={classes['data-table']}>
          <thead>
            <tr>
              <th className={classes['th-s']}>Дата</th>
              <th>Регистраций</th>
            </tr>
          </thead>
          <tbody>
            {adata.daily?.map((item) => (
              <tr key={item.date}>
                <td>
                  <strong>{item.date}</strong>
                </td>
                <td>{item.count}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Panel>
  )
}

export default ARegistrations

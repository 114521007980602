import { NavLink } from 'react-router-dom'
import classes from './AnalyticsMenuItem.module.css'

const AnalyticsMenuItem = (props) => {
  return (
    <li key={props.path} className={classes['a-menu_item']}>
      <NavLink
        className={(navData) => {
          return navData.isActive ? classes.active : ''
        }}
        to={props.path}
      >
        {props.title}
      </NavLink>
    </li>
  )
}

export default AnalyticsMenuItem

import { useState } from 'react'
import { Fragment } from 'react'
import { Outlet } from 'react-router-dom'
import AARecords from '../Analytics/AARecords'
import AnalyticsMenu from '../Analytics/AnalyticsMenu/AnalyticsMenu'

const PageAnalytics = (props) => {
  const [activeItems, setActiveItems] = useState(['AMonthly'])

  const updateActiveItems = (itemToCheck) => {
    // let currentItems = [...activeItems]

    // if (currentItems.includes(itemToCheck)) {
    //   currentItems = currentItems.filter((item) => {
    //     return item !== itemToCheck
    //   })
    // } else {
    //   currentItems.push(itemToCheck)
    // }
    setActiveItems([itemToCheck])
  }

  return (
    <Fragment>
      <AnalyticsMenu activeItems={activeItems} onUpdate={updateActiveItems} />
      <Outlet />
    </Fragment>
  )
}

export default PageAnalytics

import { authActions } from './auth-slice'

// Action creators functions
export const processLogin = () => {
  return (dispatch) => {
    localStorage.setItem('isLogged', true)
    dispatch(authActions.login())
  }
}

export const checkLocalLogin = () => {
  return (dispatch) => {
    const isLogged = localStorage.getItem('isLogged')

    if (isLogged) {
      dispatch(authActions.login())
    }
  }
}

export const processLogout = () => {
  return (dispatch) => {
    localStorage.removeItem('isLogged')
    dispatch(authActions.logout())
  }
}

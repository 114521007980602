import classes from './OrdersTableRow.module.css'
import {
  MdCreditCard,
  MdCheckCircle,
  MdPayments,
  MdError,
  MdHelp,
  MdSettings,
  MdPending,
  MdDoNotDisturbOn,
} from 'react-icons/md'
import {
  FaCcPaypal,
  FaShareSquare,
  FaExternalLinkSquareAlt,
  FaList,
} from 'react-icons/fa'

import { convertCurrency, extractUTM } from '../../helpers'

const OrdersTableRow = (props) => {
  let date = new Date(props.date).toLocaleDateString('en-GB', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  })

  let total = convertCurrency(props.total)

  let utm = extractUTM(props.utm)

  const actionClickHandler = (action) => {
    props.onAction(action)
  }

  return (
    <tr>
      <td>
        <span
          title="Удалить?"
          className={classes['action-icon']}
          onClick={() => {
            actionClickHandler({ type: 'delete', id: props.id })
          }}
        >
          <MdDoNotDisturbOn size="1rem" className="color-red" />
        </span>
      </td>
      <td>
        <div className={classes['action-edit-wrapper']}>
          <span
            title="Редактировать"
            className={`${classes['action-icon']}`}
            onClick={() => {
              actionClickHandler({ type: 'edit', id: props.id })
            }}
          >
            <MdSettings size="1rem" />
          </span>
          {props.id}
        </div>
      </td>
      <td>
        <div className={classes['action-edit-wrapper']}>
          <span
            title="Получить ссылки?"
            className={classes['action-icon']}
            onClick={() => {
              actionClickHandler({ type: 'getlinks', id: props.id })
            }}
          >
            <FaExternalLinkSquareAlt size="0.9rem" />
          </span>
          <span>{props.product}</span>
        </div>
      </td>
      <td className={classes['no-wrap']}>{date}</td>
      <td className={classes['no-wrap']}>
        {props.paymentMethod === 'online' && <MdCreditCard size="1.1rem" />}
        {props.paymentMethod === 'manual' && <MdPayments size="1.1rem" />}
        {props.paymentMethod === 'paypal' && <FaCcPaypal size="1.1rem" />}
        {props.status === 'paid' && (
          <MdCheckCircle size="1.1rem" className="color-green" />
        )}
        {props.status === 'unpaid' && (
          <span
            title="Перепроверить?"
            className={classes['action-icon']}
            onClick={() => {
              actionClickHandler({ type: 'recheck', id: props.id })
            }}
          >
            <MdHelp size="1.1rem" className="color-grey" />
          </span>
        )}
        {props.status === 'processing' && (
          <span
            title="Подтвердить?"
            className={classes['action-icon']}
            onClick={() => {
              actionClickHandler({ type: 'markaspaid', id: props.id })
            }}
          >
            <MdPending size="1.1rem" className="color-yellow" />
          </span>
        )}
        {props.status === 'failed' && (
          <span
            title="Удалить?"
            className={classes['action-icon']}
            onClick={() => {
              actionClickHandler({ type: 'delete', id: props.id })
            }}
          >
            <MdError className="color-red" size="1.1rem" />
          </span>
        )}
      </td>
      <td
        className={`${props.status === 'processing' && 'color-yellow'} ${
          props.status !== 'paid' &&
          props.status !== 'processing' &&
          'color-grey'
        }  ${classes['no-wrap']}`}
      >
        {total}
      </td>
      <td className={classes['no-wrap']}>
        <div className={classes['action-edit-wrapper']}>
          <span
            title="Отправить email еще раз?"
            className={classes['action-icon']}
            onClick={() => {
              actionClickHandler({ type: 'resend', id: props.id })
            }}
          >
            <FaShareSquare size="1rem" />
          </span>
          <span
            title="Добавить в ремаркетинг?"
            className={classes['action-icon']}
            onClick={() => {
              actionClickHandler({ type: 'addremarketing', id: props.id })
            }}
          >
            <FaList size="1rem" />
          </span>

          <span>{props.email}</span>
        </div>
      </td>
      <td>{props.promocode}</td>
      <td>{props.leadsource}</td>
      <td>{utm}</td>
    </tr>
  )
}

export default OrdersTableRow

import { Fragment } from 'react'
import { useParams } from 'react-router-dom'
import OrdersTableRecipes from '../OrdersTable/OrdersTableRecipes'
import WidgetsRecipes from '../Widgets/WidgetsRecipes'

const PageOrdersRecipes = (props) => {
  const params = useParams()
  const user = params.user

  return (
    <Fragment>
      <WidgetsRecipes />
      <OrdersTableRecipes user={user} />
    </Fragment>
  )
}

export default PageOrdersRecipes

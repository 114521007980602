import { useEffect, useState, useCallback } from 'react'
import { API_BOOK_URL } from '../../config'
import { convertCurrency } from '../../helpers'
import Panel from '../UI/Panel'
import classes from './atable.module.css'

const AAvgInvoice = () => {
  const [adata, setAdata] = useState([])

  // Fetch orders
  const fetchData = useCallback(async (loader = true) => {
    try {
      const response = await fetch(API_BOOK_URL + '?act=stats_avginvoice')

      if (!response.ok) {
        throw new Error('Ошибка доступа к API.')
      }

      const data = await response.json()

      setAdata(data.data)
    } catch (error) {
      console.log(error.message)
    }
  }, [])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return (
    <Panel>
      <div className={classes['title-wrapper']}>
        <h1>Средний чек - помесячно</h1>
      </div>

      <div className={classes['atable-wrapper']}>
        <table className={classes['data-table']}>
          <thead>
            <tr>
              <th className={classes['th-s']}>Месяц</th>
              <th className={classes['th-s']}>Продажи</th>
              <th className={classes['th-s']}>Количество</th>
              <th>Средний чек</th>
            </tr>
          </thead>
          <tbody>
            {adata.map((item) => (
              <tr key={item.label}>
                <td>
                  <strong>{item.label}</strong>
                </td>
                <td>{convertCurrency(item.total)}</td>
                <td>{item.count}</td>
                <td>
                  {convertCurrency(
                    item.count === 0 ? 0 : item.total / item.count
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Panel>
  )
}

export default AAvgInvoice

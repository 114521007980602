import classes from './ModalDialog.module.css'
import { MdClose } from 'react-icons/md'
import { Fragment } from 'react'
import ReactDOM from 'react-dom'

const ModalDialog = (props) => {
  const closeHandler = () => {
    props.onClose()
  }

  const portalElement = document.getElementById('modals')

  return (
    <Fragment>
      {ReactDOM.createPortal(
        <Fragment>
          <div
            className={classes['modal-backdrop']}
            onClick={closeHandler}
          ></div>
          <div className={classes['modal-dialog']}>
            {props.title && <h3>{props.title}</h3>}
            <div
              className={classes['modal-close-button']}
              onClick={closeHandler}
            >
              <MdClose size="1.2em" />
            </div>
            {props.children}
          </div>
        </Fragment>,
        portalElement
      )}
    </Fragment>
  )
}

export default ModalDialog

import { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import Logo from './components/Layout/Logo'
import Message from './components/UI/Message'
import Panel from './components/UI/Panel'
import { API_BOOK_URL } from './config'
import classes from './Login.module.css'
import { processLogin } from './store/auth-actions'
import { authActions } from './store/auth-slice'

const Login = (props) => {
  const dispatch = useDispatch()

  const usernameField = useRef('')
  const passwordField = useRef('')

  const [error, setError] = useState('')

  const processLoginHandler = (e) => {
    e.preventDefault()

    checkLoginHandler({
      username: usernameField.current.value,
      password: passwordField.current.value,
    })
  }

  const checkLoginHandler = async (data) => {
    const response = await fetch(API_BOOK_URL + '?act=auth', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    })

    const result = await response.json()

    if (result.type === 'error') {
      setError(result.message)
    }

    if (result.type === 'success') {
      dispatch(processLogin())
      dispatch(authActions.setUser(usernameField.current.value))
    }
  }
  //eZGf0b1qzDx
  return (
    <div className={classes['login-wrapper']}>
      <div className={classes['logo-wrapper']}>
        <Logo />
      </div>
      <Panel>
        {error && <Message type="error" content={error} dismissable={false} />}
        <form onSubmit={processLoginHandler} method="post" action="/">
          <div className={classes['control']}>
            <label>Логин</label>
            <input type="text" ref={usernameField} required name="username" />
          </div>
          <div className={classes['control']}>
            <label>Пароль</label>
            <input
              type="password"
              ref={passwordField}
              required
              name="password"
            />
          </div>
          <div className={classes['control']}>
            <button type="submit">Войти</button>
          </div>
        </form>
      </Panel>
    </div>
  )
}

export default Login

import { useEffect, useState, useCallback } from 'react'
import { API_BOOK_URL, aProductsList } from '../../config'
import { convertCurrency, sumValues, getPercentage } from '../../helpers'
import Panel from '../UI/Panel'
import classes from './atable.module.css'
import AMonthlyGraph from './AMonthlyGraph'

const AMonthly = () => {
  const [adata, setAdata] = useState([])

  // Fetch orders
  const fetchData = useCallback(async (loader = true) => {
    try {
      const response = await fetch(API_BOOK_URL + '?act=stats_monthly')

      if (!response.ok) {
        throw new Error('Ошибка доступа к API.')
      }

      const data = await response.json()

      let objects = []

      Object.entries(data.data).forEach((item) => {
        let objEntry = {
          label: item[0],
          total: 0,
          count: 0,
        }

        aProductsList.forEach((el) => {
          objEntry[el] = {
            total: item[1][el] ? item[1][el].total : 0,
            count: item[1][el] ? item[1][el].count : 0,
          }

          objEntry.total = objEntry.total + objEntry[el].total
          if (item[1][el]) {
            objEntry.count = objEntry.count + objEntry[el].count
          }
        })

        objects.push(objEntry)
      })

      setAdata(objects)
    } catch (error) {
      console.log(error.message)
    }
  }, [])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  // Totals
  let totals = {}
  let counts = {}

  aProductsList.forEach((product) => {
    totals[product + '_total'] = 0
    counts[product + '_count'] = 0

    adata.forEach((item) => {
      totals[product + '_total'] =
        totals[product + '_total'] + item[product].total
      counts[product + '_count'] =
        counts[product + '_count'] + item[product].count
    })
  })

  const sumTotal = sumValues(totals)
  const sumCounts = sumValues(counts)

  return (
    <Panel>
      <div className={classes['title-wrapper']}>
        <h1>Заказы Mi.Book - помесячно</h1>
      </div>

      <div className={classes['graph-wrapper']}>
        <AMonthlyGraph data={adata} />
      </div>

      <div className={classes['atable-wrapper']}>
        <table className={classes['data-table']}>
          <thead>
            <tr>
              <th>Месяц</th>
              {aProductsList.map((product) => (
                <th key={product}>{product}</th>
              ))}
              <th>Итого</th>
            </tr>
          </thead>
          <tbody>
            {adata.map((item) => (
              <tr key={item.label}>
                <td>
                  <strong>{item.label}</strong>
                </td>
                {aProductsList.map((product) => (
                  <td key={product}>
                    {convertCurrency(item[product].total)} /{' '}
                    {item[product].count}
                  </td>
                ))}
                <td>
                  {convertCurrency(item.total)} / {item.count}
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <th>Итого</th>
              {aProductsList.map((product) => (
                <th key={product}>
                  {convertCurrency(totals[product + '_total'])} (
                  {getPercentage(totals[product + '_total'], sumTotal)}){' / '}
                  {counts[product + '_count']}
                </th>
              ))}
              <th>
                {convertCurrency(sumTotal)} / {sumCounts}
              </th>
            </tr>
          </tfoot>
        </table>
      </div>
    </Panel>
  )
}

export default AMonthly

import { Fragment } from 'react'
import { useParams } from 'react-router-dom'
import OrdersTable from '../OrdersTable/OrdersTable'
import Widgets from '../Widgets/Widgets'

const PageOrders = (props) => {
  const params = useParams()
  const user = params.user

  return (
    <Fragment>
      <Widgets />
      <OrdersTable user={user} />
    </Fragment>
  )
}

export default PageOrders

import Widget from './Widget'
import classes from './WidgetsRecipes.module.css'
import { MdToday, MdDateRange } from 'react-icons/md'
import { useState, useEffect, useCallback } from 'react'
import { API_REFRESH, API_RECIPES_URL } from '../../config.js'

const WidgetsRecipes = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [stats, setStats] = useState({})

  // Fetch orders
  const fetchStatsHandler = useCallback(async (loader = true) => {
    if (loader) {
      setIsLoading(true)
    }

    try {
      const response = await fetch(API_RECIPES_URL + '?act=getstats')

      if (!response.ok) {
        throw new Error('Ошибка доступа к API.')
      }

      const data = await response.json()

      setStats(data)
      console.log(data)
    } catch (error) {
      //setError(error.message)
    }

    if (loader) {
      setIsLoading(false)
    }
  }, [])

  // Use Effects
  useEffect(() => {
    fetchStatsHandler()
  }, [fetchStatsHandler])

  // Live view
  useEffect(() => {
    const interval = setInterval(() => {
      fetchStatsHandler(false)
    }, API_REFRESH)

    return () => clearInterval(interval)
  })

  return (
    <div className={classes['widgets-wrapper']}>
      <Widget
        title="Сегодня"
        value={isLoading ? 0 : stats.revenue_today}
        total={isLoading ? '...' : stats.orders_today}
        icon={<MdToday size="1.4em" />}
      />
      <Widget
        title="Всего"
        value={isLoading ? 0 : stats.revenue_total}
        total={isLoading ? '...' : stats.orders_total}
        icon={<MdDateRange size="1.4em" />}
      />
    </div>
  )
}

export default WidgetsRecipes

import { useEffect, useState, useCallback } from 'react'
import { API_RECIPES_URL } from '../../config'
import Panel from '../UI/Panel'
import classes from './atable.module.css'
import { convertCurrency, getPercentage } from '../../helpers'

const AGeneralRecipes = () => {
  const [adata, setAdata] = useState([])

  // Fetch orders
  const fetchData = useCallback(async (loader = true) => {
    try {
      const response = await fetch(API_RECIPES_URL + '?act=stats_general')

      if (!response.ok) {
        throw new Error('Ошибка доступа к API.')
      }

      const data = await response.json()

      setAdata(data.data)
    } catch (error) {
      console.log(error.message)
    }
  }, [])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return (
    <Panel>
      <div className={classes['title-wrapper']}>
        <h1>Статистика Mi.Рецепты</h1>
      </div>

      <div className={classes['atable-wrapper']}>
        <table className={classes['data-table']}>
          <thead>
            <tr>
              <th className={classes['th-l']}>Пользователи</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Всего</td>
              <td>{adata.users}</td>
            </tr>
            <tr>
              <td>Активно</td>
              <td>
                {adata.users_active} (
                {getPercentage(adata.users_active, adata.users)})
              </td>
            </tr>
            <tr>
              <td>Оплатили</td>
              <td>
                {adata.users_paid} (
                {getPercentage(adata.users_paid, adata.users)})
              </td>
            </tr>
            <tr>
              <td>Оплатили повторно</td>
              <td>
                {adata.users_repaid} (
                {getPercentage(adata.users_repaid, adata.users_paid)})
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className={classes['atable-wrapper']}>
        <table className={classes['data-table']}>
          <thead>
            <tr>
              <th className={classes['th-l']}>Прибыль</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Всего</td>
              <td>{convertCurrency(adata.total_revenue)}</td>
            </tr>
            <tr>
              <td>Максимум в месяц №1</td>
              <td>
                {convertCurrency(adata.max_revenue?.value)} /{' '}
                {adata.max_revenue?.date}
              </td>
            </tr>
            <tr>
              <td>Максимум в месяц №2</td>
              <td>
                {convertCurrency(adata.max_revenue_second?.value)} /{' '}
                {adata.max_revenue_second?.date}
              </td>
            </tr>
            <tr>
              <td>Максимум в день №1</td>
              <td>
                {convertCurrency(adata.max_revenue_day?.value)} /{' '}
                {adata.max_revenue_day?.date}
              </td>
            </tr>
            <tr>
              <td>Максимум в день №2</td>
              <td>
                {convertCurrency(adata.max_revenue_day_second?.value)} /{' '}
                {adata.max_revenue_day_second?.date}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className={classes['atable-wrapper']}>
        <table className={classes['data-table']}>
          <thead>
            <tr>
              <th className={classes['th-l']}>Заказы</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Всего</td>
              <td>{adata.total_orders}</td>
            </tr>
            <tr>
              <td>Максимум в месяц №1</td>
              <td>
                {adata.max_orders?.value} / {adata.max_orders?.date}
              </td>
            </tr>
            <tr>
              <td>Максимум в месяц №2</td>
              <td>
                {adata.max_orders_second?.value} /{' '}
                {adata.max_orders_second?.date}
              </td>
            </tr>
            <tr>
              <td>Максимум в день №1</td>
              <td>
                {adata.max_orders_day?.value} / {adata.max_orders_day?.date}
              </td>
            </tr>
            <tr>
              <td>Максимум в день №2</td>
              <td>
                {adata.max_orders_day_second?.value} /{' '}
                {adata.max_orders_day_second?.date}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className={classes['atable-wrapper']}>
        <table className={classes['data-table']}>
          <thead>
            <tr>
              <th className={classes['th-l']}>Подписки</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Активно</td>
              <td>
                {adata.subscription_active} (
                {getPercentage(adata.subscription_active, adata.users_paid)})
              </td>
            </tr>
            <tr>
              <td>Отменено пользователями</td>
              <td>
                {adata.subscription_cancelled} (
                {getPercentage(adata.subscription_cancelled, adata.users_paid)})
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className={classes['atable-wrapper']}>
        <table className={classes['data-table']}>
          <thead>
            <tr>
              <th className={classes['th-l']}>Разное</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Рецептов</td>
              <td>{adata.recipes}</td>
            </tr>
            <tr>
              <td>Просмотров рецептов</td>
              <td>{adata.views}</td>
            </tr>
            <tr>
              <td>Создано рационов</td>
              <td>{adata.ration}</td>
            </tr>
            <tr>
              <td>Приготовлено рецептов</td>
              <td>{adata.recipes_done}</td>
            </tr>
            <tr>
              <td>Сейчас в избранном</td>
              <td>{adata.favourites}</td>
            </tr>
            <tr>
              <td>Сейчас в шоплистах</td>
              <td>{adata.shoplist}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </Panel>
  )
}

export default AGeneralRecipes

import React from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { aProductsList } from '../../config'
import { Colors } from 'chart.js'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Colors,
  Title,
  Tooltip,
  Legend
)
export const options = {
  plugins: {
    colors: {
      enabled: true,
    },
    title: {
      display: false,
      text: '',
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
}

const AMonthlyGraph = (props) => {
  let datasets = []
  let labels = props.data.map((item) => {
    return item.label
  })

  aProductsList.forEach((product) => {
    const dataset = {
      label: product,
      data: [],
    }

    props.data.forEach((item) => {
      dataset.data.push(item[product].total)
    })

    datasets.push(dataset)
  })

  return <Bar options={options} data={{ labels, datasets: datasets }} />
}

export default AMonthlyGraph

import { useDispatch } from 'react-redux'
import { processLogout } from '../../store/auth-actions'
import Logo from './Logo'
import classes from './TopMenu.module.css'
import TopMenuItem from './TopMenuItem'

const TopMenu = (props) => {
  const dispatch = useDispatch()

  const logoutHandler = () => {
    dispatch(processLogout())
  }

  return (
    <div className={classes['top-menu']}>
      <div className={classes['top-menu-left']}>
        <Logo />
      </div>
      <div className={classes.title}>{props.title}</div>
      <div className={classes.actions}>
        <TopMenuItem title="Mi.Book" page="/orders" />
        <TopMenuItem title="Mi.Рецепты" page="/ordersrecipes" />
        <TopMenuItem title="Аналитика" page="/analytics" />
        <TopMenuItem title="Выйти" page="/logout" onClick={logoutHandler} />
      </div>
    </div>
  )
}

export default TopMenu

import { Fragment, useEffect, useState } from 'react'
import { Route, Routes, Navigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import classes from './App.module.css'
import TopMenu from './components/Layout/TopMenu'
import PageAnalytics from './components/Pages/PageAnalytics'
import PageOrders from './components/Pages/PageOrders'
import { getPageTitle } from './helpers'
import Login from './Login'
import { checkLocalLogin } from './store/auth-actions'
import AMonthly from './components/Analytics/AMonthly'
import ARepayments from './components/Analytics/ARepayments'
import AAvgInvoice from './components/Analytics/AAvgInvoice'
import Message from './components/UI/Message'
import PageOrdersRecipes from './components/Pages/PageOrdersRecipes'
import AMonthlyRecipes from './components/Analytics/AMonthlyRecipes'
import ALogins from './components/Analytics/ALogins'
import ARegistrations from './components/Analytics/ARegistrations'
import AUTM from './components/Analytics/AUTM'
import AGeneralRecipes from './components/Analytics/AGeneralRecipes'
import AGeneral from './components/Analytics/AGeneral'

function App() {
  const isLogged = useSelector((state) => state.auth.isLogged)
  const [pageTitle, setPageTitle] = useState('')

  const dispatch = useDispatch()

  // Update page title
  const location = useLocation()
  const page = location.pathname

  useEffect(() => {
    setPageTitle(getPageTitle(page))
  }, [page])

  // Process login/logout
  useEffect(() => {
    dispatch(checkLocalLogin())
  }, [dispatch])

  return isLogged ? (
    <Fragment>
      <TopMenu title={pageTitle} />
      <div className={classes['layout-wrapper']}>
        <Routes>
          <Route path="/" element={<Navigate to="/orders" replace />} />
          <Route path="/ordersrecipes" element={<PageOrdersRecipes />} />
          <Route path="/orders" element={<PageOrders />} />
          <Route path="/analytics/*" element={<PageAnalytics />}>
            <Route path="" element={<Navigate to="general" replace />} />
            <Route path="generalrecipes" element={<AGeneralRecipes />} />
            <Route path="general" element={<AGeneral />} />
            <Route path="monthly" element={<AMonthly />} />
            <Route path="monthlyrecipes" element={<AMonthlyRecipes />} />
            <Route path="registrations" element={<ARegistrations />} />
            <Route path="logins" element={<ALogins />} />
            <Route path="repayments" element={<ARepayments />} />
            <Route path="avginvoice" element={<AAvgInvoice />} />
            <Route path="utm" element={<AUTM />} />
            <Route
              path="*"
              element={
                <Message
                  type="error"
                  content="Выберите отчет."
                  dismissable={false}
                />
              }
            />
          </Route>
          <Route path="*" element={<p>Страница не найдена</p>} />
        </Routes>
      </div>
    </Fragment>
  ) : (
    <Routes>
      <Route path="/logout" element={<Navigate to="/" replace />} />
      <Route path="/" element={<Login />} />
    </Routes>
  )
}

export default App

import React from 'react'
import ReactDOM from 'react-dom/client'

import store from './store'
import { Provider } from 'react-redux'

import { BrowserRouter } from 'react-router-dom'
import App from './App'

import 'normalize.css'
import './index.css'

import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

const root = ReactDOM.createRoot(document.getElementById('root'))
// const isDevelopment =
//   !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? true : false
// const basename = isDevelopment ? '' : APP_PROD_PATH

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
)

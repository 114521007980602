import classes from './OrdersTableFilter.module.css'

import { MdRefresh, MdLock, MdLockOpen } from 'react-icons/md'
import { convertDateFormatForInput } from '../../helpers'

const OrdersTableFilter = (props) => {
  const dateChangedHandler = (e) => {
    const date = e.target.value
    props.onDateChange(date)
  }
  const dateToChangedHandler = (e) => {
    props.onDateToChange(e.target.value)
  }

  const dateSetTodayHandler = () => {
    props.onReset()
  }

  const queryChangeHandler = (e) => {
    props.onQueryChange(e.target.value)
  }

  const refreshHandler = (e) => {
    props.onRefresh()
  }

  const lockHandler = (e) => {
    props.onLock()
  }

  return (
    <div className={classes['data-table-filters']}>
      <div className={classes['wrapper-left']}>
        <input
          type="date"
          value={convertDateFormatForInput(props.date)}
          onChange={dateChangedHandler}
        />
        <div
          className={`${props.locked && classes['active']} ${
            classes['lock-button']
          }`}
          onClick={lockHandler}
        >
          {props.locked ? <MdLock size="1.2em" /> : <MdLockOpen size="1.2em" />}
          <span>Синхронизировать даты</span>
        </div>
        <input
          type="date"
          value={convertDateFormatForInput(props.dateTo)}
          onChange={dateToChangedHandler}
        />

        <button onClick={dateSetTodayHandler}>Сегодня</button>
      </div>
      <div className={classes['wrapper-right']}>
        <button
          className={`${classes['btn-refresh']}`}
          onClick={refreshHandler}
        >
          <MdRefresh size="1.2em" />
        </button>
        <input
          type="text"
          placeholder="Поиск email..."
          onChange={queryChangeHandler}
          value={props.query}
        />
      </div>
    </div>
  )
}

export default OrdersTableFilter

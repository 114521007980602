import classes from './Message.module.css'
import { MdClose } from 'react-icons/md'

const Message = (props) => {
  const closeHandler = () => {
    props.onClose()
  }

  let dismissable = props.dismissable
  if (props.dismissable !== false) {
    dismissable = true
  }

  return (
    <div className={classes['message-wrapper']}>
      <div
        dangerouslySetInnerHTML={{ __html: props.content }}
        className={`${classes['message']} ${classes[props.type]}`}
      ></div>
      {dismissable && (
        <div className={classes['close-btn']} onClick={closeHandler}>
          <MdClose size="1.2em" />
        </div>
      )}
    </div>
  )
}

export default Message

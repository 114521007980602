import classes from './AnalyticsMenu.module.css'
import AnalyticsMenuItem from './AnalyticsMenuItem'

const AnalyticsMenu = (props) => {
  return (
    <div className={classes['a-menu']}>
      <ul>
        <li className={classes['a-menu-title']}>Mi.Book</li>
        <AnalyticsMenuItem path="general" title="Общая" />
        <AnalyticsMenuItem path="monthly" title="Заказы" />
        <AnalyticsMenuItem path="avginvoice" title="Средний чек" />
      </ul>
      <ul>
        <li className={classes['a-menu-title']}>Mi.Рецепты</li>
        <AnalyticsMenuItem path="generalrecipes" title="Общая" />
        <AnalyticsMenuItem path="monthlyrecipes" title="Заказы" />
        <AnalyticsMenuItem path="repayments" title="Подписки" />
        <AnalyticsMenuItem path="registrations" title="Регистрации" />
        <AnalyticsMenuItem path="logins" title="Логины" />
        <AnalyticsMenuItem path="utm" title="UTM и Промокоды" />
      </ul>
    </div>
  )
}

export default AnalyticsMenu
